<template>
  <div class="login">
    <div class="logo">
      <img src="../../assets/img/logo02.png" alt="" />
    </div>
    <div class="input-container">
      <div class="ipt-cont">
        <a-input v-model="loginNum" :placeholder="this.$t('帳號')" />
        <div class="redcolor">{{ numerr }}</div>
      </div>
      <div class="ipt-cont">
        <a-input-password v-model="loginPwd" :placeholder="this.$t('密碼')" />
        <div class="redcolor">{{ pwderr }}</div>
      </div>
      <div>
        <a-button @click="getLogin()" type="primary"
          ><loading v-show="isLoad"></loading
          ><span style="display: inline-block; margin-left: 10px">
            {{ this.$t("登入") }}</span
          ></a-button
        >
      </div>
      <div>
        <a-checkbox :checked="ischecked" @change="onChange">
          {{ this.$t("記住登入資訊") }}
        </a-checkbox>
      </div>
      <div class="link-wrap">
        <router-link
          :to="{
            path: '/register',
            query: client_id
              ? { client_id: client_id, return_url: return_url }
              : {},
          }"
        >
          <span>{{ this.$t("會員註冊") }}</span>
        </router-link>
        <a-divider type="vertical" />
        <router-link :to="{ path: '/find?tab=0' }">
          <span>{{ this.$t("查詢帳號") }}</span>
        </router-link>
        <a-divider type="vertical" />
        <router-link :to="{ path: '/find?tab=1' }">
          <span>{{ this.$t("查詢密碼") }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../../api/request";
import validate from "../../utils/validate";
import Bus from "../../utils/bus";
import { encrypt } from "../../utils/jsencrypt";
import loading from "../../components/Loading";
export default {
  components: {
    loading,
  },
  data() {
    return {
      isLoad: false,
      loginNum: "",
      loginPwd: "",
      ischecked: false,
      login: false,
      logininfo: "",
      numerr: "",
      pwderr: "",
      client_id: "",
      return_url: "",
    };
  },
  created() {
    let logininfo = JSON.parse(window.localStorage.getItem("login_info"));
    if (logininfo != null) {
      this.ischecked = !this.ischecked;
      this.loginNum = logininfo.loginname;
      this.loginPwd = logininfo.loginpwd;
      this.login = true;
    }
  },
  watch: {
    loginPwd(newValue, oldValue) {
      if (oldValue !== "") this.login = false;
    },
  },
  methods: {
    onChange(e) {
      this.ischecked = e.target.checked;
    },
    getLogin() {
      let _this = this;
      let res = validate.passwordLogin(_this.loginPwd);
      if (_this.loginNum == "") {
        _this.numerr = _this.$t("請輸入賬號");
        return;
      } else {
        _this.numerr = "";
      }
      if (_this.loginPwd == "") {
        _this.pwderr = _this.$t("請輸入密碼");
        return;
      } else {
        _this.pwderr = "";
      }
      if (res) {
        _this.pwderr = _this.$t(res);
        return;
      } else {
        _this.pwderr = "";
      }
      let data;
      if (_this.login == true) {
        data = {
          username: _this.loginNum,
          password: _this.loginPwd,
        };
      } else {
        data = {
          username: _this.loginNum,
          password: encrypt(_this.loginPwd),
        };
      }
      let encrypted_password = data["password"];
      _this.isLoad = true;
      request.post("/login", data).then(function (res) {
        if (res.status == 200) {
          // _this.$message.success('登入成功');
          // window.localStorage.setItem("token", res.data.data.access_token);
          // window.localStorage.setItem(
          //     "user_info",
          //     JSON.stringify(res.data.user)
          // );
          //登录成功产生token放到store
          _this.$store.commit("setToken", res.data.data.access_token);
          //登录成功后取出用户名放到store
          _this.$store.commit("setUserInfo", res.data.user);
          _this.message();
          _this.isLoad = false;
          if (_this.ischecked == true) {
            window.localStorage.setItem(
              "login_info",
              JSON.stringify({
                loginname: _this.loginNum,
                loginpwd: encrypted_password,
              })
            );
          } else {
            window.localStorage.removeItem("login_info");
          }
          if (_this.client_id && _this.return_url) _this.oauth2Redirect();
          else
            setTimeout(function () {
              _this.$router.push({
                name: "center",
              });
            }, 2000);
        } else {
          _this.isLoad = false;
          _this.$message.error(res.detail);
          _this.loginNum = "";
          _this.loginPwd = "";
        }
      });
    },
    message() {
      Bus.$emit("getusername", "成功");
    },
    oauth2Redirect() {
      request
        .get("/login/oauth2", {
          response_type: "code",
          client_id: this.client_id,
          redirect_uri: this.return_url,
        })
        .then(function (res) {
          if (res.status == 200) {
            window.location.href = res.data.url;
          }
        });
    },
  },
  mounted() {
    if (this.$route.query.client_id && this.$route.query.return_url) {
      this.client_id = this.$route.query.client_id;
      this.return_url = this.$route.query.return_url;
    }
  },
  // watch:{
  //     userPhone(newValue, oldValue){

  //     }
  // }
};
</script>

<style lang="scss">
.login {
  max-width: 1000px;
  margin: 100px auto;
  min-height: 800px;
  padding: 30px;
  border-radius: 10px;
  .logo {
    text-align: center;
    margin-bottom: 50px;
  }
  .input-container {
    max-width: 600px;
    margin: 0 auto;
    input {
      height: 50px;
      line-height: 50px;
      font-size: 22px;
    }
    .ipt-cont {
      margin-bottom: 30px;
    }
    button {
      width: 100%;
      height: 50px;
      line-height: 50px;
      margin-bottom: 20px;
      font-size: 22px;
    }
    .ant-checkbox-inner {
      width: 30px;
      height: 30px;
    }
    .ant-checkbox-inner::after {
      width: 10px;
      height: 20px;
    }
    .ant-checkbox + span {
      font-size: 20px;
    }
    .link-wrap {
      text-align: center;
      margin-top: 50px;
      padding: 50px 0;
      width: 100%;
      font-size: 20px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      a {
        color: rgba(0, 0, 0, 0.65);
      }
      span {
        width: 30%;
        display: inline-block;
        font-size: 22px;
      }
      .ant-divider,
      .ant-divider-vertical {
        height: 20px;
        background: #ccc;
      }
    }
  }
}
</style>
