var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_vm._m(0),_c('div',{staticClass:"input-container"},[_c('div',{staticClass:"ipt-cont"},[_c('a-input',{attrs:{"placeholder":this.$t('帳號')},model:{value:(_vm.loginNum),callback:function ($$v) {_vm.loginNum=$$v},expression:"loginNum"}}),_c('div',{staticClass:"redcolor"},[_vm._v(_vm._s(_vm.numerr))])],1),_c('div',{staticClass:"ipt-cont"},[_c('a-input-password',{attrs:{"placeholder":this.$t('密碼')},model:{value:(_vm.loginPwd),callback:function ($$v) {_vm.loginPwd=$$v},expression:"loginPwd"}}),_c('div',{staticClass:"redcolor"},[_vm._v(_vm._s(_vm.pwderr))])],1),_c('div',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getLogin()}}},[_c('loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoad),expression:"isLoad"}]}),_c('span',{staticStyle:{"display":"inline-block","margin-left":"10px"}},[_vm._v(" "+_vm._s(this.$t("登入")))])],1)],1),_c('div',[_c('a-checkbox',{attrs:{"checked":_vm.ischecked},on:{"change":_vm.onChange}},[_vm._v(" "+_vm._s(this.$t("記住登入資訊"))+" ")])],1),_c('div',{staticClass:"link-wrap"},[_c('router-link',{attrs:{"to":{
          path: '/register',
          query: _vm.client_id
            ? { client_id: _vm.client_id, return_url: _vm.return_url }
            : {},
        }}},[_c('span',[_vm._v(_vm._s(this.$t("會員註冊")))])]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('router-link',{attrs:{"to":{ path: '/find?tab=0' }}},[_c('span',[_vm._v(_vm._s(this.$t("查詢帳號")))])]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('router-link',{attrs:{"to":{ path: '/find?tab=1' }}},[_c('span',[_vm._v(_vm._s(this.$t("查詢密碼")))])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/img/logo02.png"),"alt":""}})])
}]

export { render, staticRenderFns }